import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import * as styles from './line.module.scss';

const Line = () => {
  return (
    <Container>
      <Row className="d-none d-md-block">
        <Col md={{ offset: 2, span: 8 }} className={styles.column}></Col>
      </Row>
    </Container>
  );
};

export default Line;
