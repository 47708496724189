import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import { useIntl } from 'gatsby-plugin-intl';
import showdown from 'showdown';
import c from 'classnames';

import LinkButton from '../LinkButton/LinkButton';
import Line from '../../components/Line/Line';
import * as styles from './services.module.scss';

const Services = ({ service }) => {
  const converter = new showdown.Converter();
  const intl = useIntl();

  const firstColumn = service.services;
  const secondColumn = service.uniques;
  const image1 = service.image1;
  const image2 = service.image2;

  return (
    <Container>
      <Row className={styles.section}>
        <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
          {firstColumn.map((service, index) => (
            <div key={index} className="text-left">
              <h3 className="title">{service.title}</h3>
              <span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(service.paragraph)
                  }}
                />
              </span>
              <p
                className={styles.price}
                style={{
                  marginBottom: index === firstColumn.length - 1 && '0'
                }}
              >
                {service.price}
              </p>
              {index === 3 && (
                <p className="button" style={{ marginTop: '24px' }}>
                  <LinkButton
                    path="/contact"
                    title={intl.formatMessage({ id: 'button.lets_plan' })}
                  />
                </p>
              )}
            </div>
          ))}
        </Col>
        <Col
          xs={{ span: 12, order: 1 }}
          md={{ span: 6, order: 2 }}
          className={c(styles.image, 'text-center')}
        >
          <GatsbyImage
            image={getImage(image1)}
            alt={service.image1Alt}
            className="image"
          />
        </Col>
      </Row>
      <Line />
      <Row className={styles.section}>
        <Col xs={{ span: 12, order: 2 }} md={{ span: 6, order: 1 }}>
          {secondColumn.map((unique, index) => (
            <div key={index} className="text-left">
              <h3 className="title">{unique.title}</h3>
              <span>
                <div
                  dangerouslySetInnerHTML={{
                    __html: converter.makeHtml(unique.paragraph)
                  }}
                />
              </span>
              <p
                className={styles.price}
                style={{
                  marginBottom: index === secondColumn.length - 1 && '0'
                }}
              >
                {unique.price}
              </p>
              {index === 1 && (
                <p className="button" style={{ marginTop: '24px' }}>
                  <LinkButton
                    path="/contact"
                    title={intl.formatMessage({ id: 'button.lets_plan' })}
                  />
                </p>
              )}
            </div>
          ))}
        </Col>
        <Col
          xs={{ span: 12, order: 1 }}
          md={{ span: 6, order: 2 }}
          className={c(styles.image, 'text-center')}
        >
          <GatsbyImage
            image={getImage(image2)}
            alt={service.image2Alt}
            className="image"
          />
        </Col>
      </Row>
      <Line />
    </Container>
  );
};

export default Services;
