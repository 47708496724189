import React from 'react';
import c from 'classnames';
import { Container, Row, Col } from 'react-bootstrap';
import Line from '../../components/Line/Line';
import * as styles from './price_terms.module.scss';

const PriceTerms = ({ term }) => {
  return (
    <Container>
      <Line />
      <Row className={styles.section1}>
        <Col md={{ span: 6, offset: 3 }} className="text-center">
          {term.terms.map((term, index) => (
            <div key={index}>
              {index === 0 ? (
                <p>{term.paragraph}</p>
              ) : (
                <p>- {term.paragraph}</p>
              )}
            </div>
          ))}
        </Col>
      </Row>
      <Line />
      <Row className={styles.section2}></Row>
    </Container>
  );
};

export default PriceTerms;
