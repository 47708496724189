import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import showdown from 'showdown';
import c from 'classnames';

import Line from '../../components/Line/Line';
import * as styles from './tematics.module.scss';

const Tematics = ({ tematic }) => {
  const converter = new showdown.Converter();

  const tematics = tematic.tematics;
  const image = tematic.image;

  return (
    <Container>
      <Row className={styles.section}>
        <Col
          xs={{ span: 12, order: 2 }}
          md={{ span: 6, order: 1 }}
          className={styles.tematic}
        >
          <h3 className="title">{tematic.title}</h3>

          {tematics.map((tematic, index) => (
            <div key={index}>
              <ul className={styles.list}>
                <li>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: converter.makeHtml(tematic.paragraph)
                    }}
                  />
                </li>
              </ul>
            </div>
          ))}
        </Col>
        <Col
          xs={{ span: 12, order: 1 }}
          md={{ span: 6, order: 2 }}
          className={c(styles.image, 'text-center')}
        >
          <GatsbyImage
            image={getImage(image)}
            alt={tematic.imageAlt}
            className="image"
          />
        </Col>
      </Row>
      <Line />
    </Container>
  );
};

export default Tematics;
