import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { useIntl } from 'gatsby-plugin-intl';
import showdown from 'showdown';
import * as styles from './planning_process.module.scss';
import LinkButton from '../LinkButton/LinkButton';
import {
  BubbleIcon,
  KitchenIcon,
  MapIcon,
  RollerIcon
} from '../../images/index';

const PlanningProcess = ({ plans }) => {
  const converter = new showdown.Converter();
  const intl = useIntl();

  return (
    <Container className={styles.planningContainer}>
      <Row className={styles.titleSection}>
        <Col className="text-center">
          <h3 className="title">{plans.title}</h3>
        </Col>
      </Row>
      <Row>
        <Col md={6} className="text-center">
          <BubbleIcon className={styles.icon} />
          <p className={styles.paragraph}>
            <span>
              <div
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(plans.paragraph1)
                }}
              />
            </span>
          </p>
          <MapIcon className={styles.icon} />
          <p className={styles.paragraph}>
            <span>
              <div
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(plans.paragraph2)
                }}
              />
            </span>
          </p>
        </Col>
        <Col md={6} className="text-center">
          <KitchenIcon className={styles.icon} />
          <p className={styles.paragraph}>
            <span>
              <div
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(plans.paragraph3)
                }}
              />
            </span>
          </p>
          <RollerIcon className={styles.icon} />
          <p className={styles.paragraph}>
            <span>
              <div
                dangerouslySetInnerHTML={{
                  __html: converter.makeHtml(plans.paragraph4)
                }}
              />
            </span>
          </p>
        </Col>
      </Row>
      <Row>
        <Col className={styles.buttonColumn}>
          <p className="button">
            <LinkButton
              path="/contact"
              title={intl.formatMessage({ id: 'button.lets_plan' })}
            />
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default PlanningProcess;
